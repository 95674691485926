<script setup lang="ts">
import type { Faq_Plain } from '@webamboos/admin'
const { $posthog } = useNuxtApp()

defineProps<{
  items: Faq_Plain[]
  disabledAfter?: number
}>()

const accordion = ref<HTMLElement | null>(null)
const sendFaqEvent = (faqTitle: string, e: HTMLElement) => {
  if (!e) return
  const state = e.getAttribute('data-state')
  if (state === 'closed') {
    $posthog.capture('faq_clicked', {
      title: faqTitle,
    })
  }
}
</script>

<template>
  <AccordionRoot ref="accordion" class="w-full overflow-hidden rounded-md" type="single" :collapsible="true">
    <template v-for="(item, i) in items" :key="item.title">
      <AccordionItem
        class="mt-px overflow-hidden border-b border-gray-200 first:mt-0 first:rounded-t last:rounded-b last:border-b-0"
        :value="item.title" :disabled="disabledAfter ? i > disabledAfter - 1 : false">
        <AccordionHeader class="flex">
          <AccordionTrigger :data-faq="item.title"
            class="ph-no-capture group flex flex-1 items-center justify-between py-4 text-base leading-none text-gray-500 transition focus:bg-gray-100"
            @click="(e) => sendFaqEvent(item.title, e?.target)">
            <Paragraph size="normal" align="left" weight="medium" class="flex-1 pointer-events-none">{{ item.title }}
            </Paragraph>
            <Icon name="lucide:chevron-down" size="18"
              class="text-green10 transition-transform duration-300 ease-[cubic-bezier(0.87,_0,_0.13,_1)] group-data-[state=open]:rotate-180 pointer-events-none"
              aria-hidden />
          </AccordionTrigger>
        </AccordionHeader>

        <AccordionContent
          class="data-[state=open]:animate-slideDown data-[state=closed]:animate-slideUp overflow-hidden text-gray-800">
          <div class="py-2">
            <RichText class="text-sm !text-gray-800 lg:text-base" :raw="item.body" />
          </div>
        </AccordionContent>
      </AccordionItem>
    </template>
  </AccordionRoot>
</template>
